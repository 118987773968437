import React from "react";
import MenuBar from "../MenuBar";
import backgroundImage from "../../assets/northstar/bg_img.png";
import { navigate } from "gatsby";
import oktaImg from "../../assets/northstar/okta.png";
import { genAuthorizationUrl } from "../../services/okta";
import ElevateCard from "../ElevateCard";
import Rapid7Beacon from "../Rapid7Beacon";

const NorthstarLogin: React.FC = () => {
  return (
    <>
      <MenuBar NorthstarMenu={true} />
      <div
        className="px-8 pt-24 md:pt-32 bg-white flex justify-center items-center h-full w-full"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="w-[360px]">
          <ElevateCard title="Login Via Single Sign-On">
            {process.env.GATSBY_APPLICATION_URL ===
              "https://northstar.bamelevate.com" && <Rapid7Beacon />}
            <div className="flex justify-center mt-3">
              <div
                className="border border-gray-light p-3 rounded-md cursor-pointer"
                onClick={async () => {
                  const url = await genAuthorizationUrl();
                  navigate(url.href);
                }}
              >
                <img src={oktaImg} className="h-[30px] "></img>
              </div>
            </div>
          </ElevateCard>
        </div>
      </div>
    </>
  );
};

export default NorthstarLogin;
